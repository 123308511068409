<template>
	<router-view>
	</router-view>
</template>

<script>
// import { reactive, onMounted, ref, toRefs } from 'vue';
// import { login } from './utils';

export default {
	name: 'App',
	components: {

	},
	setup() {
        
    }
}
</script>

<style>
@import "./assets/css/rest.css";
@import "./assets/css/css.css";
/* html, body{
	width: 100%;
	height: 100%;
} */

</style>
