
export default {
	testId: -1,
	productId: -1,
	utid: -1,
	curTestNum: -1,
	title: '',
	reportId: 0,
	curType:0,//首页分类
	userType:0,//我的测评分类
}
