import { config } from "@/config";
import { Toast } from 'vant';

export function queryString(item) {
    var sValue = location.search.match(new RegExp("[\?\&]" + item + "=([^\&]*)(\&?)", "i"));
    return sValue ? sValue[1] : sValue;
}

// 判断当前页面是否登录
function loginHelper(url) {
    const redirectUri = encodeURIComponent(`${config.URL}login?metaCallback=${url}`);
    // const redirectUri = encodeURIComponent(`${config.URL}login`);
    window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx9ee605c9b206596c&redirect_uri=${redirectUri}&response_type=code&scope=snsapi_userinfo&state=aaa#wechat_redirect`;
}

export function login(url) {
    const loginToken = window.localStorage.getItem('token');
    Toast('提示内容loginToken', loginToken);
    if (loginToken == null) {
        loginHelper(url);
    } else {
        const { time, token } = JSON.parse(loginToken);
        console.log(time, token);
        const curTime = new Date().getTime();
        if (curTime - time >= config.OVERTIME) {
            // loginHelper(url);
        }
    }
}

export function formatDate(date) {
    // var date = new Date();
    var seperator1 = "-";
    var year = date.getFullYear();
    var month = date.getMonth() + 1;
    var strDate = date.getDate();
    if (month >= 1 && month <= 9) {
      month = "0" + month;
    }
    if (strDate >= 0 && strDate <= 9) {
      strDate = "0" + strDate;
    }
    var currentdate = year + seperator1 + month + seperator1 + strDate;
    return currentdate;
}
