import {createRouter, createWebHistory} from 'vue-router';
import { defineAsyncComponent } from 'vue';
import { queryString } from '@/utils';
import { login } from '@/service/login';
import { config } from '@/config';
import store from '../store/index';
import wx from 'weixin-js-sdk'
import { getcfg } from "@/service/wxShare";
import { getContent} from "@/service/content";
const router = createRouter({ 
	history: createWebHistory(),  // history 模式
	routes: [
	{
		path: '/',
		name: 'index',
		component: defineAsyncComponent(() => import(`../views/index.vue`)),
		meta: {
			title: '首页',
		},
	},
	{
		path: '/recommend',
		name: 'recommend',
		component: defineAsyncComponent(() => import(`../views/recommend.vue`)),
		meta: {
			title: '推荐页',
		},
	},
	{
		path: '/content',
		name: 'content',
		component: defineAsyncComponent(() => import(`../views/content.vue`)),
		meta: {
			title: '内容页',
		},
	},
	{
		path: '/userInfo',
		name: 'userInfo',
		component: defineAsyncComponent(() => import(`../views/students/userInfo.vue`)),
		meta: {
			title: '基本信息',
		},
	},
	{
		path: '/test',
		name: 'test',
		component: defineAsyncComponent(() => import(`../views/students/test.vue`)),
		meta: {
			title: '答题',
		},
	},
	{
		path: '/reportLite',
		name: 'reportLite',
		component: defineAsyncComponent(() => import(`../views/students/reportLite.vue`)),
		meta: {
			title: '简版报告',
		},
	},
	{
		path: '/reportFull',
		name: 'reportFull',
		component: defineAsyncComponent(() => import(`../views/students/reportFull.vue`)),
		meta: {
			title: '完整报告',
		},
	},
	{
		path: '/user',
		name: 'user',
		component: defineAsyncComponent(() => import(`../views/user/index.vue`)),
		meta: {
			title: '我的测试',
		},
	},
	{
		path: '/adapter',
		name: 'adapter',
		component: defineAsyncComponent(() => import(`../views/adapter/userInfo.vue`)),
		meta: {
			title: '我的测试',
		},
	},
	{
		path: '/*',
		redirect: '/',
	},
	{
		path: '/testReport',
		name: 'testReport',
		component: defineAsyncComponent(() => import(`../views/testReport.vue`)),
		meta: {
			title: '测试报告',
		},
	},
	]
});

/**
 * 处理url链接
 */
// function processUrl () {
// 	const url = window.location.href
// 	// 解决多次登录url添加重复的code与state问题
// 	const params = parse(url.split('?')[1])
// 	let redirectUrl = url
// 	if (params.code && params.state) {
// 	  delete params.code
// 	  delete params.state
// 	  const query = stringify(params)
// 	  if (query.length) {
// 		redirectUrl = `${url.split('?')[0]}?${query}`
// 	  } else {
// 		redirectUrl = `${url.split('?')[0]}`
// 	  }
// 	}
// 	return redirectUrl
// }

function getWXCode() {
	const redirectUri = encodeURIComponent(window.location.href);
	let newURL = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx9ee605c9b206596c&redirect_uri=${redirectUri}&response_type=code&scope=snsapi_userinfo&state=aaa#wechat_redirect`;
	window.location.replace(newURL);
}

function getQueryString(name) { 
	var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i"); 
	var r = window.location.search.substr(1).match(reg); 
	if (r != null) return decodeURI(r[2]); 
	return null; 
}

router.beforeEach(async (to, from, next) => {
	// window.localStorage.setItem('token', JSON.stringify({
	// 	token: 'Bearer bzJZMXk2R1B4eXItMFFVOEdHMmswbjRkSmF1NHx8MzJ8fDU1ZTMyZTVlNzRkYzBlYWJlMDFjOTQyMWI4YTBiOTE1',
	// 	time: new Date().getTime()
	// }));
	let storageData = localStorage.getItem("token") || "";
	const code = queryString('code');
	if (!storageData) {
		if (!code) {
			getWXCode();
		} else {
			const { data } = await login(code);
            // 将token存储到localstorge
            window.localStorage.setItem('token', JSON.stringify({
                token: data.token,
                time: new Date().getTime()
            }));
		}
	} else {
		const { token, time } = JSON.parse(storageData);
		const curTime = new Date().getTime();
        if (curTime - time >= config.OVERTIME) {
            // loginHelper(url);
			localStorage.removeItem("token");
			getWXCode();
        }
	}
	if(to.name == 'test' || to.name == 'content' || to.name == 'reportLite' || to.name == 'reportFull'){
		document.title = store.state.title
	}else{
		document.title = '脉塔心理'
	}


// 微信分享自定义设置
getcfg(encodeURIComponent(location.href))    //访问接口获取以下需要的参数
.then((res) => {
		wx.config({
			debug: false,
			appId: res.data.appId, // 必填，公众号的唯一标识
			timestamp: res.data.timestamp, // 必填，生成签名的时间戳
			nonceStr: res.data.noncestr, // 必填，生成签名的随机串
			signature: res.data.signature,// 必填，签名
			jsApiList: [
				"checkJsApi",
				'onMenuShareTimeline',                      //分享到微信朋友圈
				'onMenuShareAppMessage',                    //分享给微信朋友
				'onMenuShareQQ',                            //分享到QQ
				'onMenuShareQZone',                         //分享到QQ空间
				"updateAppMessageShareData",  //分享到微信及QQ（新接口）
				"updateTimelineShareData",//分享到朋友圈”及“分享到QQ空间（新接口）
				"onMenuShareWeibo",//分享到微博
			] // 必填，需要使用的JS接口列表，所有JS接口列表见附录2
		});
		wx.ready(function(){
			let sharedParams = {            //分享参数(朋友圈除外)
				title: '脉塔心理', // 分享标题
				link: location.href, // 分享链接
				imgUrl:  'http://img.xinyunyiyun.cn/img/logo_1.png' // 分享图标
			}
			let sharedParamsmore = {            //分享参数(朋友圈除外)
				title: '脉塔心理', // 分享标题
				desc: '脉塔心理', // 分享描述
				link: location.href, // 分享链接
				imgUrl:  'http://img.xinyunyiyun.cn/img/logo_1.png' // 分享图标
			}
			if(to.name == 'recommend'){
				sharedParamsmore.desc = '热门推荐';
				wx.updateAppMessageShareData(sharedParamsmore);
				wx.updateTimelineShareData(sharedParams);
				wx.onMenuShareWeibo(sharedParamsmore);
			}else if(to.name == 'user'){
				sharedParamsmore.desc = '我的测评';
				wx.updateAppMessageShareData(sharedParamsmore);
				wx.updateTimelineShareData(sharedParams);
				wx.onMenuShareWeibo(sharedParamsmore);
			}else if(to.name == 'index'){
				sharedParamsmore.desc = '测评大厅';
				wx.updateAppMessageShareData(sharedParamsmore);
				wx.updateTimelineShareData(sharedParams);
				wx.onMenuShareWeibo(sharedParamsmore);
			}
			else if(to.name == 'content' || to.name == 'test' || to.name == 'userInfo' || to.name == 'reportLite' || to.name == 'reportFull'){
				console.log('12233',store)
				let productId = store.state.productId;
				let testId = store.state.testId;
				let title = store.state.title;
				sharedParams.title = title;
				sharedParamsmore.title = title;
				sharedParamsmore.link = 'http://h5.xinyunyiyun.cn/test';
				getContent(testId).then(res => {
					console.log('获取test分享数据',res);
					let desc = res.data.sub_title;
					let img = res.data.image.replace('2.png','1.png');
					let link =  'http://h5.xinyunyiyun.cn/test?isshare=true&' + 'testId=' + testId + "&productId=" + productId + "&title=" + title
					sharedParamsmore.desc = desc;
					sharedParams.link = link;
					sharedParamsmore.link = link;
					sharedParams.imgUrl = img;
					sharedParamsmore.imgUrl = img;
					console.log('test参数',sharedParamsmore,sharedParams);
					wx.updateAppMessageShareData(sharedParamsmore);
					wx.updateTimelineShareData(sharedParams);
					wx.onMenuShareWeibo(sharedParamsmore);
				})
			}

		});
})

if(to.name != 'test' & to.name != 'userInfo' & to.name != 'reportLite' & to.name != 'reportFull'){
	next()
}else{
	let isshare = getQueryString('isshare');
	if(!isshare){
		next();
	}else{//试题过程中的分享，存储url中试题信息，再跳转对应测试题的内容页 
		let testId = getQueryString('testId');
		let productId = getQueryString('productId')
		let title = getQueryString('title')
		console.log('标题title',title);
		store.commit('addTitle', title);
		store.commit('addTestId', testId);
		store.commit('addProductId', productId);
		next({
			path:'/content'
		})
	}
}

});

export default router;