import axios from 'axios'
import { Toast } from 'vant';
import { config } from '@/config'


axios.defaults.baseURL = process.env.NODE_ENV == 'development' ? config.APIURL : config.APIURL;
// axios.defaults.withCredentials = true;
// axios.defaults.headers.post['Content-Type'] = 'application/json';

axios.interceptors.request.use(config => {
    const localStorageData = localStorage.getItem('token') || '';
    
    if (localStorageData) {
        const { token } = JSON.parse(localStorageData);
        console.log('token:::', token);
        console.log('11111token:::', token);
        config.headers.Authorization = token;
    }
    // config.headers.Authorization = 'Bearer bzJZMXk2Qmc5R1dfS2Z1akdid3dMSGhPeTVFa3x8OHx8NDcxYzZjYjA4YWVhOGM4ZjY0Njg3ZGZjYjliMTY4ZGM=';
    return config;
})

axios.interceptors.response.use(res => {
    if (res.status != 200) {
        Toast.fail(`网络请求异常!`);
        return Promise.reject(res);
    }

    const { data } = res;
    console.log(data);
    if (data.code != 1) {
        Toast.fail(`服务端异常！${data.code}`);
        return Promise.reject(res);
    }

    return res.data;
})

export default axios;
 